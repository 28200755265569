<template>
  <div class='TableGroupManagement reading-container'>
    <div class="filter-panel">
      <div
          style="
                      vertical-align: middle;
                      margin-bottom: 15px;
                      display: inline-block;
                    "
      >
        <input
            v-model="inputCondition"
            class="input"
            placeholder="搜索表组名称"
            style="
                        color: #000;
                        width: 400px;
                        height: 30px;
                        padding: 5px 28px 5px 10px;
                        border-radius: 3px;
                        border: 1px solid #999999;
                        margin-right: 30px;
                      "
            type="text"
        />
      </div>
      <button class="btn btn-primary btn-sm" type="button" @click="query()">
        查询
      </button>
    </div>

    <div class="result-panel">
      <CSTable>
        <template v-slot:header>
          <div class="table-header-panel" style="text-align: right;">
            <button
                class="btn btn-primary sticky-right"
                @click="enableAddWaterGroup"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              表组
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr style="color: black">
            <th>添加时间</th>
            <th>表组名称</th>
            <th>水表</th>
            <th>电表</th>
            <th>备注</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in MeterGroupList" :key="item.id" style="color: black">
            <td>
              {{ item.createTime }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
                            <span class="allow-click" @click="seeWaterBtn(item.id,1)">
                                {{ item.waterCount || `0` }}
                            </span>
            </td>
            <td>
                            <span class="allow-click" @click="seeElectricityBtn(item.id,2)">
                                 {{ item.electricityCount || `0` }}
                            </span>
            </td>
            <td v-if="item.commit">
                            <span class="allow-click" @click="lookComment(item.commit)">
                                查看
                            </span>
            </td>
            <td v-else>
              -
            </td>
            <td>
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="width: 76px">
                  <li>
                    <a @click="enableEditWaterGroup(item)">修改</a>
                  </li>
                  <li>
                    <a @click="deleteMeterGroup(item.id)">删除</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination componentName="Pagination" name="pagination"></Pagination>
    </div>
    <!-- 添加表组 -->
    <CSDialog
        :dialog-visible="addWaterGroupFlag"
        :dialog-width="600"
        :dialogTitle="`添加表组`"
        @onClose="addWaterGroupFlag = false"
        @onConfirm="addWaterGroup"

    >
      <template v-slot:dialog-content>
        <div class="dialog-form" style="padding:30px 0">
          <div class="dialog-form-item" style="margin-bottom: 0">
            <div class="dialog-form-item-label" style="width: 130px">表组名称</div>
            <div>
              <input
                  v-model="addWaterGroupInfo.name"
                  :maxlength="20"
                  class="input"
                  placeholder="限10个字"
                  style="
                  width: 368px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                  type="text"
              />
            </div>
          </div>
          <div class="preview-tenant">
            <div class="field">
              <span class="field-label" style="width: 100px;margin-right: 40px">备注</span>
              <textarea
                  v-model="addWaterGroupInfo.commit"
                  maxlength="50"
                  placeholder="限50个字"
                  style="
                                resize:none;
                                padding:10px;
                                width:370px;
                                height:200px;
                                overflow-y:auto;
                                background-color:#F0F0F0;
                                border-radius:4px;
                                border:0px;
                            "
              ></textarea>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
    <!-- 修改表组 -->
    <CSDialog
        :dialog-visible="editWaterGroupFlag"
        :dialog-width="590"
        :dialogTitle="`修改表组`"
        @onClose="editWaterGroupFlag = false"
        @onConfirm="editWaterGroup"
    >
      <template v-slot:dialog-content>
        <div class="dialog-form" style="padding:30px 0">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">表组名称</div>
            <div>
              <input
                  v-model="editWaterGroupInfo.name"
                  :maxlength="20"
                  class="input"
                  placeholder="限10个字"
                  style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                  type="text"
              />
            </div>
          </div>
          <div class="preview-tenant">
            <div class="field">
              <span class="field-label" style="width: 120px">备注</span>
              <textarea
                  v-model="editWaterGroupInfo.commit"
                  maxlength="50"
                  placeholder="限50个字"
                  style="
                                resize:none;
                                padding:10px;
                                width:370px;
                                height:200px;
                                overflow-y:auto;
                                background-color:#F0F0F0;
                                border-radius:4px;
                                border:0px;
                            "
              ></textarea>
            </div>
          </div>
        </div>
      </template>
    </CSDialog>

  </div>
</template>

<script>
import CSTable from "@/components/common/CSTable";
import {addMeterGroupUrl, deleteMeterGroupUrl, editMeterGroupUrl, queryMeterGroupUrl} from "@/requestUrl";
import CSDialog from "@/components/common/CSDialog";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "TableGroupManagement",
  data() {
    return {
      inputCondition: '',
      MeterGroupList: [],
      editWaterGroupFlag: false,
      addWaterGroupFlag: false,
      editWaterGroupInfo: {
        id: "",
        name: "",
        commit: ""
      },
      addWaterGroupInfo: {
        regionCode: "",
        name: "",
        commit: ""
      },
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  components: {
    CSTable,
    Pagination,
    CSDialog
  },
  created() {
    this.query();
  },
  mounted() {
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      this.query(pageNo);
    });
  },
  methods: {
    //添加表组
    enableAddWaterGroup() {
      this.addWaterGroupInfo.commit = "";
      this.addWaterGroupInfo.name = "";
      this.addWaterGroupFlag = true;
    },
    deleteMeterGroup: function deleteMeterGroup(id) {
      var _this9 = this;
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定删除表组吗？',
        onConfirm: function onConfirm() {
          _this9.$fly.get(deleteMeterGroupUrl, {
            id: id
          }).then(function (res) {
            if (res.code != 0) {
              return;
            }
            _this9.$vc.toast('删除成功');
            _this9.query();
            _this9.$CSDialog.instance.closeDialog();
          });
        }
      });
    },
    lookComment: function lookComment(comment) {
      this.$CSDialog.alert({
        title: '查看备注',
        message: comment
      });
    },
    seeElectricityBtn(id, type) {
      let params = {
        groupId: id,
        type: type
      };
      //带过去的参数
      this.$router.push({
        name: "electricityGroupInfo",
        query: params,
      });
    },
    seeWaterBtn(id, type) {
      let params = {
        groupId: id,
        type: type
      };
      //带过去的参数
      this.$router.push({
        name: "waterGroupInfo",
        query: params,
      });
    },
    editWaterGroup() {
      this.$fly.post(editMeterGroupUrl, {
        id: this.editWaterGroupInfo.id,
        name: this.editWaterGroupInfo.name,
        commit: this.editWaterGroupInfo.commit,
      })
          .then(res => {
            if (res.code != 0) {
              return;
            }
            this.$vc.toast("修改成功");
            this.editWaterGroupFlag = false;
            this.query();
          })
    },
    //查询
    query(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly.post(queryMeterGroupUrl, {
        search: this.inputCondition, //搜索框内容
        pageSize: pageSize, //页面大小
        pageNo: pageNo, //页码
        regionCode: this.$vc.getCurrentRegion().code, //区域编码
      })
          .then(res => {
            if (res.code != 0) {
              return;
            }
            if (pageNo == 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                currentPage: pageNo,
                pageSize: pageSize,
              });
            }
            this.MeterGroupList = res.data.datas
          })
    },
    //添加表组
    enableEditWaterGroup(item) {
      this.editWaterGroupFlag = true;
      this.editWaterGroupInfo.id = item.id
      this.editWaterGroupInfo.name = item.name
      this.editWaterGroupInfo.commit = item.commit
    },
    addWaterGroup() {
      this.$fly.post(addMeterGroupUrl, {
        regionCode: this.$vc.getCurrentRegion().code, //区域编码
        name: this.addWaterGroupInfo.name,
        commit: this.addWaterGroupInfo.commit,
      })
          .then(res => {
            if (res.code != 0) {
              return;
            }
            this.$vc.toast("保存成功");
            this.addWaterGroupFlag = false;
            this.query();
          })
    },
  },

}
</script>

<style lang="stylus">
input[type='checkbox']
  width 20px !important
  height 0 !important
  vertical-align top !important
  margin-top 12px

.reading-container
  .bioz
    .el-icon-caret-bottom
      padding-top 5px

  .preview-tenant
    color #000
    padding 30px
    font-size 24px
    overflow-y auto

    .field
      &:not(:last-of-type)
        margin-bottom 20px

      &-label
        width 60px
        display inline-block
        margin-right 40px
        text-align right
        vertical-align top

      &-content
        display inline-block
        max-width calc(100% - 240px)

  .btn-active {
    color: #1dafff;
    text-decoration: underline;
    cursor: pointer;
  }

  .footable.table.table-stripped.toggle-arrow-tiny span {
    line-height: 20px;
    height: 20px;
  }

  .tenant-detail-dialog
    .el-dialog__header
      display none

    .preview-tenant
      color #000
      padding 30px
      font-size 24px
      overflow-y auto

      .field
        &-label
          width 170px
          display inline-block
          margin-right 40px
          text-align right
          vertical-align top

        &-content
          display inline-block
          max-width calc(100% - 240px)

    .el-divider__text.is-left
      font-size 24px

  .dialog-form
    padding 27px 30px
    font-size 24px
    color #000

    &-item
      &:not(:last-of-type)
        margin-bottom 23px

      &-label
        width 156px
        height 33px
        text-align right
        margin-right 40px
        display inline-block
        vertical-align middle
        line-height 33px

      & > div
        display inline-block
        vertical-align middle

        input
          &::placeholder
            color #999
          width 140px
          height 40px
          border-radius 4px
          padding 0 5px
          border 1px solid #979797
          vertical-align middle
          padding-left 10px

.wData i {
  padding-top 0px !important
}
</style>
